.SideBar {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  border-right: 1px solid;
  border-radius: 0;
  border: none;
  background-color: #8ca9a0;
  transition: 0.8s ease;
  position: fixed;
  z-index: 1;
}

.SideBar .SideBar_Button {
  height: 65px;
  border-top-right-radius: 11rem;
  border-bottom-right-radius: 10rem;
  width: 17px;
  position: absolute;
  outline: none;
  z-index: 1;
  background-color: #f57b20;
  border-color: #f57b20;
  border-left: 0;
  cursor: pointer;
}

.SideBar .SideBar_Nav {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.SideBar .SideBar_Button i {
  left: 0;
  top: 25px;
  text-align: center;
  position: fixed;
  color: #fff;
}

.SideBar .SideBar_Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.SideBar .SideBar_Wrapper button {
  background-color: #07402d;
  border: 2px solid #07402d;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  margin: 10px 5px;
  cursor: pointer;
}

.SideBar .SideBar_Wrapper button:hover {
  background-color: #fff;
  color: #07402d;
  transition: 0.3s all ease-in-out;
}

.SideBar .SideBar_Nav button:hover {
  background-color: #fff;
  color: #07402d;
  transition: 0.3s all ease-in-out;
}

.SideBar .SideBar_Nav .Push_Paving_Schedule_Button {
  background-color: #f57b20;
  border: 2px solid #f57b20;
}

.SideBar .SideBar_Wrapper .Push_Paving_Schedule_Button:hover {
  color: #f57b20;
}

.SideBar .SideBar_Nav .Push_Paving_Schedule_Button:hover {
  color: #f57b20;
}

.SideBar_Logo {
  background-color: #fff;
  padding: 15px;
}

.SideBar_Logo img {
  width: auto;
  max-width: 140px;
}

.SideBar .SideBar_Nav .wrap {
  display: flex;
  justify-content: space-between;
}

.SideBar .SideBar_Nav .wrap .Equipment_Details_Management {
  background-color: transparent;
  width: auto;
  max-width: 30px;
  border: none;
  padding: 0;
  margin-left: 10px;
}

.SideBar .SideBar_Nav .Bubble_Notifications_Button {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  width: auto;
  max-width: 30px;
  /* margin-left: auto; */
  margin-right: 10px;
}


.SideBar .SideBar_Nav .Bubble_Notifications_Button .label {
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -9px;
  right: -6px;
}

.SideBar .Middle_Buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}