.Disable_User {
  width: auto;
  height: auto;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: 0 auto;
  overflow: hidden;
  min-width: 400px;
}

.Disable_User_Header {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Disable_User_Header .Date_Wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Disable_User_Header .Date_Wrap img {
  height: auto;
  width: auto;
  max-width: 40px;
}

.Disable_User_Header .Title {
  font-size: 14px;
  font-family: Verdana, sans-serif;
  width: auto;
  display: inline-block;
  padding-left: 5px;
}

.Disable_User_Header .Close {
  width: auto;
  max-width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.Disable_User_Content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.Disable_User_Content .Wrap_Select {
  position: relative;
  width: 100%;
  padding-bottom: 15px;
}

.Disable_User_Content .Wrap_Select::after {
  content: "\f0dc";
  font-family: "Font Awesome 5 Free";
  width: 5px;
  height: 5px;
  font-size: 15px;
  position: absolute;
  right: 12px;
  top: 10px;
  font-weight: 900;
  color: #f57b20;
}

.Disable_User_Content .Wrap_Select select {
  width: 100%;
  height: 33px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  border: 1px solid #e4e4e4;
  position: relative;
  padding-left: 0.2rem;
  font-size: 14px;
}

.Disable_User_Content .Change_User_Button {
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
}

.Disable_User_Content .Change_User_Button:hover {
  background-color: #fff;
  color: #003F2D;
  transition: 0.3s;
}