.NothingToSave.Add_Crew {
  width: 500px;
  height: 150px;
}
.ErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}
.ErrorActionHolder {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.ErrorSign {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100%;
  background: red;
  border: 1px solid black;
}
.Arrow1 {
  width: 30px;
  background-color: white;
  height: 5px;
  position: absolute;
  top: calc(50% - 2.5px);
  left: calc(50% - 15px);
  transform: rotate(45deg);
  transform-origin: center;
  border-radius: 3px;
}
.Arrow2 {
  width: 30px;
  background-color: white;
  height: 5px;
  position: absolute;
  top: calc(50% - 2.5px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  transform-origin: center;
  border-radius: 3px;
}
.NothingToSave button {
  width: 100px;
}
