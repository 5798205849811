@keyframes blink {
  50% {
    border-color: red;
  }
}

.Sheet_Row .Row_Item {
  width: 50px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  display: inherit;
  justify-content: center;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.Sheet_Row .Row_Item:nth-child(2n) {
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
}

.Sheet_Row .Row_Item.Small {
  width: 50px;
}

.Sheet_Row .Row_Item.Small:disabled {
  cursor: not-allowed;
}

.Sheet_Row .Row_Item.Medium {
  width: 80px;
}

.Sheet_Row .Row_Item.Medium:disabled {
  cursor: not-allowed;
}

.Sheet_Row .Row_Item.Medium .Select {
  height: 100%;
  text-align: center;
  border: none;
}

.Sheet_Row .Row_Item.Medium .Select:disabled {
  cursor: not-allowed;
}

.Sheet_Row .Row_Item.Medium .Select_Red {
  /* background-color: rgba(255,0,0, 0.5); */
  /* border: none; */
  border: 3px solid rgba(255,0,0, 0.75);
}

.Sheet_Row .Row_Item.Medium .Blink_Border {
  border: 3px solid #fff;
  animation: blink 1.3s;
  animation-iteration-count: infinite;
}

.Sheet_Row .Row_Item.Large {
  width: 140px;
}

.Sheet_Row .Row_Item.Large:disabled {
  cursor: not-allowed;
}

.Sheet_Row .Row_Item.Large .Select {
  height: 100%;
  text-align: center;
  border: none;
}

.Sheet_Row .Row_Item.Large .Select:disabled {
  cursor: not-allowed;
}

.Row_Item.Medium.grinder {
  width: 100px;
}

.Row_Item.Small.input {
  width: 50px;
  height: 100%;
  overflow: hidden;
  text-align: center;
  padding-left: 1px;
  padding-right: 1px;
  align-items: center;
  display: inherit;
  justify-content: center;
  /* border: 1px solid #000; */
  border: none;
}

.Row_Item.Small.input.Blink_Border {
  border: 3px solid #fff;
  animation: blink 1.3s;
  animation-iteration-count: infinite;
}

.Row_Item.NoWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: start;
}