.Paving_Module_Calendar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-left: 1rem;
}

.week {
  display: flex;
  flex-direction: row;
}