.main {
  width: auto;
  height: auto;
  background-color: #fff;
  z-index: 3;
}

.main .main_title {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.main .main_title .wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.main .main_title .wrap .text{
  white-space: "pre-wrap"
}

.main .main_title .wrap .title {
  font-size: 14px;
  color: #5d5d5d;
  padding-left: 0.5rem;
}

.main .main_title .wrap img {
  height: auto;
  width: auto;
  max-width: 30px;
}

.main .body {
  padding: 1rem 2rem;
}

.main .body .message {
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-bottom: 2rem;
  width: 100%;
  background-color: #fff;
  z-index: 3;
}

.main .body .actionHolder {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-right: 0;
  margin: 0;
}

.main .WrapButton {
  display: flex;
  padding-bottom: 1rem;
  padding-right: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.SendButton {
  background-color: #f57b20;
	border: 2px solid #f57b20;
	color: #fff;
	font-size: 14px;
	padding: 0.4rem 1.2rem;
	margin: 0 0.2rem;
	font-family: Verdana, sans-serif;
	transition: 0.3s;
	cursor: pointer;
}

.SendButton:hover {
	background-color: #fff;
	color: #f57b20;
	transition: 0.3s;
}

.CancelButton {
	background-color: #003F2D;
	border: 2px solid #003F2D;
	color: #fff;
	font-size: 14px;
	padding: 0.4rem 1.2rem;
	margin: 0 0.2rem;
	font-family: Verdana, sans-serif;
	transition: 0.3s;
	cursor: pointer;
}

.CancelButton:hover {
	background-color: #fff;
	color: #003F2D;
	transition: 0.3s;
}