.Menu_Calendar {
  z-index: 3;
  background-color: #fff;
  border-radius: 2px;
  padding-left: 0;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0px 2px 10px #999999;
}

.Menu_Calendar .Menu_Icons {
  display: flex;
  align-items: center;
  padding: 0.8rem;
}

.Menu_Calendar .Menu_Icons button {
  font-family: Verdana, sans-serif;
  font-size: 13px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}