.SaveSuccesful.Add_Crew {
  width: auto;
  height: auto;
}
.ErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 100%;
}
.SaveSuccesful .ErrorActionHolder {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.InfoSign {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100%;
  background: blue;
  position: relative;
  border: 1px solid black;
}
.InfoSign .Arrow1 {
  width: 24px;
  background-color: white;
  height: 4px;
  position: absolute;
  top: calc(50% + 2px);
  left: calc(50% - 12px);
  transform: rotate(90deg);
  transform-origin: center;
  border-radius: 3px;
}
.InfoSign .Arrow2 {
  width: 4px;
  background-color: white;
  height: 4px;
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 2px);
  transform: rotate(-45deg);
  transform-origin: center;
  border-radius: 3px;
}
.SaveSuccesful button {
  width: 100px;
}

.SaveSuccesful .New_Crew_Title {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.SaveSuccesful .Wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.SaveSuccesful .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.SaveSuccesful .Wrap img {
  height: auto;
  width: auto;
  max-width: 30px;
}