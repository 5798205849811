.ExpandableSheet {
  width: auto;
  border-right: 1px solid #e4e4e4;
  height: 100%;
  position: relative;
}


.ExpandableSheet > button {
  position: absolute;
  top: 5px; 
  right: 0px;
  cursor: pointer;
  font-weight: 600;
  padding: 2px 3px;
}

.ExpandableSheet > button:hover {
  transition: 0.1;
  background-color:#07402d;
  color: #fff;
}