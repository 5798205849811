.TruckingResourcesNotAllocated {
  width: auto;
  height: auto;
  background-color: #f3f3f3;
  max-width: 500px;
}
.TruckingResourcesNotAllocated .ErrorMessage {
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
}
.TruckingResourcesNotAllocated .TruckingResourcesNotAllocated_Body {
  padding: 1rem 2rem;
  text-align: center;
}
.TruckingResourcesNotAllocated .ErrorActionHolder {
  display: flex;
  padding-bottom: 1rem;
  padding-right: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.TruckingResourcesNotAllocated button {
  width: 100px;
  margin-left: 20px;
}

.TruckingResourcesNotAllocated .New_Crew_Title {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.TruckingResourcesNotAllocated .Wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.TruckingResourcesNotAllocated .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.TruckingResourcesNotAllocated .Wrap img {
  height: auto;
  width: auto;
  max-width: 30px;
}