.Paving_Calendar {
  background-color: #f0f0f0;
  border: 1px solid gray;
  position: relative;
}

.Paving_Calendar .PavingCalendar_Header {
  padding: 5px;
  background-color: #fff;
}

.Paving_Calendar .PavingCalendar_Header .Title {
  font-size: 14px;
  width: calc(100% - 35px);
  display: inline-block;
  padding-left: 0px;
}

.PavingCalendar_Header .Close {
  width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
  border: none;
  background-color: white;
}

.Paving_Calendar .Calendar {
  padding: 3rem 3.2rem;
}

.Calendar .flatpickr-weekdays {
  background-color: rgb(165, 200, 193);
}

.Calendar .flatpickr-months .flatpickr-month {
  background-color: rgb(165, 200, 193);
}

.Calendar span.flatpickr-weekday {
  background-color: rgb(165, 200, 193);
}

.arrow {
  border-radius: 50%;
  padding: 5px 10px;
  border: 2px solid #a5c8c1;
}

button.arrow.left {
  width: 39px;
  height: 39px;
  left: 10px;
  top: 230px;
  position: absolute;
}

button.arrow.left.true {
  display: none;
}

button.arrow.left:hover {
  background-color: rgb(165, 200, 193);
}

button.arrow.right {
  width: 39px;
  height: 39px;
  position: absolute;
  right: 10px;
  top: 230px;
}

button.arrow.right.true {
  display: none;
}

button.arrow.right:hover {
  background-color: rgb(165, 200, 193);
  color: #fff;
}

.Calendar .red {
  border: 1px solid red;
  border-radius: 150px;
  line-height: 35px;
  width: 39px;
  height: 39px;
}

.Calendar .blocked {
  background-color: lightgrey;
  border-radius: 150px;
  line-height: 35px;
  width: 39px;
  height: 39px;
}