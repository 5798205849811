.Manage_Book_Date_Paving_Main_Screen {
  width: calc(100% - 900px);
  min-width: 750px;
  min-height: 400px;
  height: calc(100% - 120px);
  background-color: #fff;
  border: 1px solid gray;
  margin: 0 auto;
  overflow: auto;
}

.Book_Date_Paving_Header {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Book_Date_Paving_Header .Title {
  font-size: 14px;
  width: 100%;
  display: inline-block;
  padding-left: 0px;
}

.Book_Date_Paving_Header .Close {
  width: auto;
  max-width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  overflow: hidden;
  padding-bottom: 1.5rem;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Wrap_Row {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}

.Manage_Book_Date_Paving_Main_Screen .left_side,
.Manage_Book_Date_Paving_Main_Screen .right_side {
  width: 96%;
  padding: 0 1%;
}

.Manage_Book_Date_Paving_Main_Screen .Table_Header {
  background: #fff;
  padding: 2px 5px;
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.Table_Line:nth-child(2n-1) {
  background-color: #d3d3d3;
}

.Table_Line:nth-child(2n) {
  background-color: #fff;
}

.Table_Line {
  border-bottom: 1px solid gray;
  padding: 3px 5px;
}

.Table_Line.Selected_Line {
  background-color: #f57b20;
}

.Paving_Booked_Dates {
  display: block;
  width: 96%;
  margin-top: 5px;
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid #e4e4e4;
}

.Paving_Booked_Dates_Table {
  background-color: #fff;
  border: 1px solid gray;
  height: 400px;
  max-height: 400px;
  overflow: auto;
}

.Paving_Booked_Dates_Header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
  border: 1px solid gray;
  border-bottom: none;
}

/* .Paving_Booked_Dates_Line:nth-child(2n-1) {
  background-color: #fff;
}

.Paving_Booked_Dates_Line:nth-child(2n) {
  background-color: #f3f3f3;
} */

.Paving_Booked_Dates_Line {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid gray;
  padding: 0.5rem 0;
  background-color: #fff;
}

.Paving_Booked_Dates_Line_Night {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid gray;
  padding: 0.5rem 0;
  background-color: #f3f3f3;
}

.Add_Trucking_Holder {
  width: calc(30% - 10px);
  display: inline-block;
  margin-top: 5px;
  padding-left: 5px;
}

.Manage_Book_Date_Paving_Main_Screen .Actions_Holder {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.Manage_Book_Date_Paving_Main_Screen .Action_Holder {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row {
  padding-bottom: 1rem;
  width: 100%;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row_SelectDates{
  width: 100%;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row_Slected_Dates {
  width: 100%;
  font-size: 11px;
  font-family: arial;
  color: #747474;
}
/* .Manage_Book_Date_Paving_Main_Screen .middle-content .Row * {
  display: inline-block;
} */

/* .Manage_Book_Date_Paving_Main_Screen .flatpickr-calendar.inline {
  display: block;
} */

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row_Usage {
  width: 50%;
  max-width: 100px;
  font-size: 14px;
  text-align: left;
  padding: 0 1rem 0 0.5rem;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row select.dropdown {
  width: 100%;
  height: 25px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  border: 1px solid #e4e4e4;
  position: relative;
  padding-left: 0.2rem;
  font-size: 14px;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row input {
  width: 100%;
  height: 25px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row .Wrap_Select {
  position: relative;
  width: 100%;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Row .Wrap_Select::after {
  content: "\f0dc";
  font-family: "Font Awesome 5 Free";
  width: 5px;
  height: 5px;
  font-size: 15px;
  position: absolute;
  right: 12px;
  top: 7px;
  font-weight: 900;
  color: #f57b20;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .Static_table input.inactive {
  background-color: lightgrey;
  color: #7b7a7a;
}

.Manage_Book_Date_Paving_Main_Screen .middle-content .header_item {
  text-align: center;
  padding: 0 2px;
  width: 12.5%;
  max-width: 112px;
  font-size: 11px;
  color: #747474;
}

.Manage_Book_Date_Paving_Main_Screen .Paving_Booked_Dates_Header .header_item {
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000
}

.Manage_Book_Date_Paving_Main_Screen .Save_Resource {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Manage_Book_Date_Paving_Main_Screen .Save_Resource :hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s;
}

.Manage_Book_Date_Paving_Main_Screen .Save_Resource:disabled {
  background-color: grey;
  color: #fff;
  border-color: grey;
  cursor: not-allowed;
}

.Manage_Book_Date_Paving_Main_Screen .Cancel_Book_Paving {
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Manage_Book_Date_Paving_Main_Screen .Cancel_Book_Paving:hover {
  background-color: #fff;
  color: #003F2D;
  transition: 0.3s;
}

.Manage_Book_Date_Paving_Main_Screen .Remove_Item_Paving {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Manage_Book_Date_Paving_Main_Screen .Remove_Item_Paving:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: white;
}

.Manage_Book_Date_Paving_Main_Screen .Select_Multiple_Dates {
  width: 100%;
  height: 25px;
  background-color: #003f2c;
  color: #fff;
  padding: 3.2px 1rem; 
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}