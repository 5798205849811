.Super_Pav_Calendar_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Super_Pav_Calendar_Header img {
  width: auto;
  max-width: 140px;
}

.Super_Pav_Calendar_Header .Wrap_Menu {
  display: flex;
  justify-content: space-evenly;
}

.Super_Pav_Calendar_Header .Users_Management_Button {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  border-radius: 5px;
  transition: 0.3s;
}

.Super_Pav_Calendar_Header .Users_Management_Button:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s;
}
