.Audit_Trail {
  width: 100%;
  max-width: 900px;
  min-width: 600px;
  height: auto;
  max-height: 800px;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: auto auto;
  overflow: auto;
}

.Audit_Trail_Header {
  padding: 4px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Audit_Trail_Header .Date_Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1.7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Audit_Trail_Header .Date_Wrap .Title {
  overflow: hidden;
  white-space: nowrap;
  color: blue;
  cursor: pointer;
  font-size: 14px;
}

.Audit_Trail_Header .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Audit_Trail .middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  overflow: hidden;
}

.Audit_Trail .middle-content .Paving_List_Header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
  border: 1px solid gray;
  border-bottom: none;
}

.Audit_Trail .middle-content .Paving_List_Header .header_item {
  text-align: center;
  padding: 0 2px;
  width: 12.5%;
  max-width: 112px;
  font-size: 11px;
  font-family: Verdana, sans-serif;
  color: #000;
  justify-content: center;
  align-items: center;
  display: inherit;
}


.Audit_Trail .middle-content .Paving_List {
  background-color: #fff;
  border: 1px solid gray;
  overflow: auto;
  justify-content: space-evenly;
  padding: 10px 0;
  align-items: center;
  height: 400px;
  max-height: 400px;
  overflow: auto;
}

.Audit_Trail .middle-content .Paving_Item {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 5px;
}

.Audit_Trail .middle-content .Paving_Item .item {
  text-align: center;
  padding: 0 2px;
  width: 12.5%;
  max-width: 112px;
  font-size: 11px;
}
