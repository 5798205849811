.GeneralWarningModal.Add_Crew {
  width: auto;
  height: auto;
}

.GeneralWarningModal .ErrorMessage {
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
}

.GeneralWarningModal .GeneralWarningModal_Body {
  padding: 1rem 2rem;
}

.GeneralWarningModal .ErrorActionHolder {
  display: flex;
  padding-bottom: 1rem;
  padding-right: 0;
  margin: 0;
  align-items: center;
}

.GeneralWarningModal .WrapButton {
  display: flex;
  padding-bottom: 1rem;
  padding-right: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.GeneralWarningModal .WrapButton button {
  margin-left: 0;
}

.InfoSign {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100%;
  background: blue;
  position: relative;
  border: 1px solid black;
}

.InfoSign .Arrow1 {
  width: 24px;
  background-color: white;
  height: 4px;
  position: absolute;
  top: calc(50% + 2px);
  left: calc(50% - 12px);
  transform: rotate(90deg);
  transform-origin: center;
  border-radius: 3px;
}

.InfoSign .Arrow2 {
  width: 4px;
  background-color: white;
  height: 4px;
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 2px);
  transform: rotate(-45deg);
  transform-origin: center;
  border-radius: 3px;
}

.GeneralWarningModal button {
  width: 100px;
  margin-left: 20px;
}

.GeneralWarningModal .New_Crew_Title {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.GeneralWarningModal .Wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.GeneralWarningModal .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
  padding-left: 0.5rem;
}

.GeneralWarningModal .Wrap img {
  height: auto;
  width: auto;
  max-width: 30px;
}