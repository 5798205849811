.Manage_Trucking_Main_Screen {
    width: 60%;
    min-width: 600px;
	height: calc(100% - 50px); 
    max-height: 850px;
    background-color: #f4f4f4;
    border:1px solid gray;
    margin: 0 auto;
    overflow: auto;
}
.Trucking_Header {
    padding: 5px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

.Trucking_Header .Date_Wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.Trucking_Header .Date_Wrap .Title {
    font-size: 14px;
    color: #5d5d5d;
}

.Trucking_Header .Date_Wrap img {
    height: 100%;
    width: 100%;
    max-width: 160px;
}

.Trucking_Title {
    display: flex;
    justify-content: flex-start;
    padding: 1.3rem 0;
    font-size: 18px;
    color: #003F2D;
}

.Title { 
    font-size: 14px;
    width: auto;
    display: inline-block;
    padding-left: 0px;
}
.Close {
    width: auto;
    max-width: 29px;
    display: inline-block;
    text-align: right;
    padding-right: 5px;
    cursor: pointer;
}
.Manage_Trucking_Main_Screen .middle-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding-bottom: 1.5rem;
}
.Manage_Trucking_Main_Screen .middle-content .Wrap_Inputs_Side {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
}
.Manage_Trucking_Main_Screen .middle-content .Wrap_Row {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: auto;
    align-items: center;
}
.Manage_Trucking_Main_Screen .middle-content .Wrap_Row .Row {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    height: auto;
    position: relative;
}

.Manage_Trucking_Main_Screen .middle-content .Wrap_Row .Row .Combined {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    width: 100%;
}
.Manage_Trucking_Main_Screen .left_side {
    width: 100%;
}
.Manage_Trucking_Main_Screen .right_side {
    width: 100%;
    padding: 0 1rem;
}
.Manage_Trucking_Main_Screen .Table_Header {
    background: #fff;
    padding: 2px 5px;
    font-weight: bold;
    border-bottom: 1px solid gray;
}
.Table_Line:nth-child(2n-1){
    background-color: #d3d3d3;
}
.Table_Line:nth-child(2n){
    background-color: #fff;
}
.Table_Line{
    border-bottom: 1px solid gray;
    padding: 3px 5px;
}
.Table_Line.Selected_Line {
    background-color: #f57b20;
}
.Trucking_Resources {
    display: block;
    width: 90%;
    margin-top: 5px;
    overflow: hidden;
    margin: 0 auto;
    border: 1px solid #e4e4e4;
}

.Trucking_Table {
    background-color: #fff;
    border-top: 1px solid #e4e4e4;
    height: 275px;
    overflow: auto;
}

.Trucking_Table_Header {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 0.7rem 0;
    background-color: #fff;
}

.Trucking_Resource_Line {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e4e4e4;
    padding: 0.5rem 0;
    background-color: #fff;
}
.Add_Trucking_Holder {
    width: calc(30% - 10px);
    display: inline-block;
    margin-top: 5px;
    padding-left: 5px;
}

.Manage_Trucking_Main_Screen .Actions_Holder {
    padding: 1rem;
    width: 100%;
}
.Flex_Corect {
    display: flex;
    justify-content: space-between;
}

.Action_Holder {
    padding: 1rem;
    margin: 0 auto;
    display: block;
}
.Action_Holder .Add_Truck_Resources {
    padding: 0.4rem 1.5rem;
    font-size: 14px;
    background-color: #f57b20;
    color: #fff;
    border: 2px solid #f57b20;
    transition: 0.3s;
}

.Action_Holder .Add_Truck_Resources:hover {
    background-color: #fff;
    color: #f57b20;
    transition: 0.3s;
}

.Action_Holder .Add_Truck_Resources:disabled {
	background-color: grey;
	color: #fff;
	border-color: grey;
	cursor: not-allowed;
}

.Manage_Trucking_Main_Screen .middle-content .left_side .Row {
    padding-bottom: 5px;
}

.Manage_Trucking_Main_Screen .middle-content .left_side .Row:last-child {
    padding-bottom: 0;
}

.Manage_Trucking_Main_Screen .middle-content .Row * {
    display: inline-block;
    position: relative;
}
.Manage_Trucking_Main_Screen .middle-content .Row_Usage {
    font-size: 14px;
    text-align: justify;
}
.Manage_Trucking_Main_Screen .middle-content .Row .Combined select.dropdown {
    width: 100%;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    border: 1px solid #e4e4e4;
    position: relative;
}

.Manage_Trucking_Main_Screen .middle-content .Row select.dropdown {
    width: 100%;
    height: 33px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    border: 1px solid #e4e4e4;
    position: relative;
    padding-left: 0.2rem;
    font-size: 14px;
}

.Manage_Trucking_Main_Screen .middle-content .Row .Wrap_Select {
    position: relative;
    width: 100%;
}

.Manage_Trucking_Main_Screen .middle-content .Row .Combined .Wrap_Select {
    position: relative;
    width: 55%;
}

.Manage_Trucking_Main_Screen .middle-content .Row .Wrap_Select::after {
    content: "\f0dc";
    font-family: "Font Awesome 5 Free";
    width: 5px;
    height: 5px;
    font-size: 15px;
    position: absolute;
    right: 12px;
    top: 10px;
    font-weight: 900;
    color: #f57b20;
}

.Manage_Trucking_Main_Screen .middle-content .Static_table .Row input {
    width: 30px;
    height: 25px;
}

.Manage_Trucking_Main_Screen .middle-content .right_side .Row input {
    background-color: #f3f3f3;
    border-radius: 2px;
    width: 100%;
    font-size: 14px;
}

.Manage_Trucking_Main_Screen .middle-content .Wrap_Row .Row .Combined .input-container {
    width: 40%;
    /* height: 25px; */
    height: 33px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e4e4e4;
}

.Manage_Trucking_Main_Screen .middle-content .Wrap_Row .right_side textarea {
    height: 100%;
    max-height: 185px;
    border-radius: 0;
    border: 1px solid #e4e4e4;
    width: 100%;
    font-size: 14px;
}

.Manage_Trucking_Main_Screen .middle-content .Wrap_Row .Row .Combined input {
    width: 100%;
}

.Manage_Trucking_Main_Screen .middle-content .Static_table input.inactive {
    background-color: lightgrey;
    color: #7b7a7a;
}
.Manage_Trucking_Main_Screen .middle-content .Static_table input.active {
    background-color: fff;
    font-weight: bold;
}

.Manage_Trucking_Main_Screen .middle-content .header_item {
    text-align: center;
    padding: 0 2px;
    width: 13.5%;
    max-width: 162px;
    font-size: 11px;
    color: #747474;
}

.Manage_Trucking_Main_Screen .middle-content .header_item_small {
    text-align: center;
    padding: 0 2px;
    width: 5%;
    max-width: 62px;
    font-size: 11px;
    color: #f57b20;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.Manage_Trucking_Main_Screen .middle-content .header_item_small:disabled {
    cursor: not-allowed;
    color: #747474;
}

.Manage_Trucking_Main_Screen .middle-content .Trucking_Table_Header .header_item_small {
    padding: 0 2px;
    width: 5%;
    max-width: 62px;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #000;
    cursor: none;
}

.Manage_Trucking_Main_Screen .middle-content .Trucking_Table_Header .header_item {
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #000
}

.Trucking_Before_Table {
    width: 75%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}

.Trucking_Before_Table .Trucking_Table_Title {
    justify-content: flex-start;
    padding: 1.3rem 0;
    font-size: 18px;
    color: #003F2D;
    width: auto;
}

.Trucking_Before_Table .buttons {
    width: auto;
}

.Trucking_Before_Table .buttons .Delete_Resources {
    background-color: #003F2D;
    border: 2px solid #003F2D;
    color: #fff;
    font-size: 14px;
    padding: 0.4rem 1.2rem;
    margin: 0 0.2rem;
    font-family: Verdana, sans-serif;
    transition: 0.3s;
    cursor: pointer;
}

.Trucking_Before_Table .buttons .Delete_Resources:hover {
    background-color: #fff;
    color: #003F2D;
    transition: 0.3s;
}

.Trucking_Before_Table .buttons button.Add_Resources:disabled {
    background-color: grey;
    color: grey;
}

.Trucking_Before_Table .buttons .Add_Resources {
    background-color: #f57b20;
    border: 2px solid #f57b20;
    color: #fff;
    font-size: 14px;
    padding: 0.4rem 1.2rem;
    margin: 0 0.2rem;
    font-family: Verdana, sans-serif;
    transition: 0.3s;
    cursor: pointer;
    width: auto;
}

.Trucking_Before_Table .buttons .Add_Resources:hover {
    background-color: #fff;
    color: #f57b20;
    transition: 0.3s;
}