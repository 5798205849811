.Incomplete_Items_List {
  display: block;
  position: absolute;
  background-color: rgba(245, 123, 32, 0.4);
  top: 16px;
  left: 100%;
  width: 100%;
  padding: 0.5rem 1rem;
  z-index: 1;
}

.Incomplete_Items_List .item {
  width: 100%;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.Incomplete_Items_List .CloseButton {
  float: right;
}