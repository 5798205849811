.Sheet_Header {
  display: flex;
  justify-content: space-evenly;
  width: auto;
  align-items: center;
}

.Sheet_Header .item {
  width: 80px;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  height: 100%;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  padding-left: 1px;
  padding-right: 1px;
  align-items: center;
  display: inherit;
  justify-content: center;
}

.Sheet_Header .item.small {
  width: 50px;
}

.Sheet_Header .item.medium {
  width: 80px;
}

.Sheet_Header .item.large {
  width: 140px;
}

.Sheet_Header .Item_Grinder {
  width: 100px;
  height: 100%;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  padding-left: 1px;
  padding-right: 1px;
}
