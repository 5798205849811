.Resource_Assignment_Main_Screen{
    width: 800px;
    height: 500px;
    background-color: #f0f0f0;
    border:1px solid gray;
    margin: 0 auto;
}
.Resource_Assignment_Header{
    display: flex;
    justify-content: space-between;
    padding:5px;
}

.Resource_Assignment_Main_Screen .Crew_Header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}
  
.Resource_Assignment_Main_Screen .Crew_Header .Wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.Resource_Assignment_Main_Screen .Crew_Header .Wrap .Title {
    font-size: 14px;
    color: #5d5d5d;
}

.Resource_Assignment_Main_Screen .Crew_Header .Wrap img {
    height: 100%;
    width: 100%;
    max-width: 160px;
}

.Crew_Name_Assignment{
    font-size: 16px;
    color: #ababab;
    font-weight: bold;
    padding-bottom: 5px;
    padding-left: 5px;
}
.Equipment_Limit_Assignment {
    width: 42%;
    display: flex;
    flex-direction: column;
    padding-right: 5px;
}
.Labor_Limit {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
    color: #9fa0e0;
}
.Equipment_Limit{
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;   
    color: #558859;
}

.Resource_Assignment_Main_Screen .Equipment_Table_Header {
    display: flex;
    border-bottom: 1px solid #e4e4e4;
}

.Resource_Assignment_Main_Screen .Equipment_Table_Header > div:nth-child(1),
.Resource_Assignment_Main_Screen .Resource_Name{
    width: 20%;
}
.Resource_Assignment_Main_Screen .Equipment_Table_Header > div:nth-child(2),
.Resource_Assignment_Main_Screen .Resource_Name{
    width: 40%;
}
.Resource_Assignment_Main_Screen .Equipment_Table_Header > div:nth-child(3),
.Resource_Assignment_Main_Screen .Resource_Qu{
    width: 40%;
}

.Resource_Assignment_Main_Screen .Resource_Line {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e4e4e4;
    color: #747474;
    font-family: Verdana, sans-serif;
    align-items: center;
    padding: 0.3rem 0;
    font-size: 14px;
}
.Resource_Assignment_Main_Screen .Resource_Line:nth-child(2n-1) {
    background-color: #fff;
}
.Resource_Assignment_Main_Screen .Resource_Line:nth-child(2n) {
    background-color: #fff;
}

.Resource_Assignment_Main_Screen .Resource_ID {
    width: 20%;
    padding: 3px 5px;
    border-right: 1px solid #e4e4e4;
}
.Resource_Assignment_Main_Screen .Resource_Name {
    width: 70%;
    padding: 3px 5px;
    border-right: 1px solid #e4e4e4;
}
.Resource_Assignment_Main_Screen .Resource_Qu {
    width: 10%;
    padding-left: 5px;
    padding: 3px 5px;
}

.Resource_Assignment_Main_Screen .Equipment_Table_Header > div:nth-child(1){
    width: 20%;
    padding: 5px 5px;
    border-right: 1px solid #e4e4e4;
}
.Resource_Assignment_Main_Screen .Equipment_Table_Header > div:nth-child(2){
    width: 70%;
    padding: 5px 5px;
    border-right: 1px solid #e4e4e4;
}
.Resource_Assignment_Main_Screen .Equipment_Table_Header > div:nth-child(3){
    width: 10%;
    padding: 5px 5px;
}

.Resource_Assignment_Main_Screen .Resources_Table {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    height: 390px;
    overflow: auto;
    padding-left: 5px;
  }