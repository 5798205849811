@keyframes blink {
  50% {
    border-color: red;
  }
}

.Item {
  width: 90%;
  margin: 0 auto;
  background-color: grey;
  cursor: pointer;
}

.Item.NightShift {
  background-color: #000;
  border: 3px solid #e4e4e4;
}

.Item.DayShift.SpecialShift {
  border: 3px solid rgba(245, 123, 32, 0.8);
}

.SubItem {
  font-size: 12px;
}

.Item.NightShift .SubItem {
  color: #fff;
}

.Item.NightShift .SubItem.Select {
  color: #000;
}

.Item select.Select {
  border: none;
  font-size: 13px;
  width: 30%;
}

.Item.DayShift {
  background-color: #fff;
  border: 3px solid #e4e4e4;
}

.Item.NightShift.SpecialShift {
  border: 3px solid rgba(245, 123, 32, 0.8);
}

.Day_Total {
  text-align: center;
}

.Item .Border {
  border: 3px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.Item .Green_Border {
  border: 3px solid #107418;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.Item .Blink_Border {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  border: 3px solid #e4e4e4;
  animation: blink 1.3s;
  animation-iteration-count: infinite;
}

.Item .Border.SpecialShift {
  border: 3px solid rgba(245, 123, 32, 0.8);
}