.Equipment_Resources {
  width: calc(100% - 300px);
  height: calc(100% - 100px);;
  min-height: 350px;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: 0 auto;
  overflow: hidden;
}

.Equipment_Resources .Equipment_Header {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Equipment_Resources .Equipment_Header .Date_Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Equipment_Resources .Equipment_Header .Date_Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Equipment_Resources .Equipment_Header .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Equipment_Resources .middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* padding: 1rem 0; */
}

.Equipment_Resources .middle-content .Table_Header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
}

.Equipment_Resources .middle-content .Table_Header .Header_Item {
  width: 33.3%;
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000;
  text-align: center;
}

.Equipment_Resources .middle-content .Equipment_Table_Content {
  height: calc(100% - 240px);
  overflow-y: scroll;
  overflow-x: hidden;
}


.Equipment_Resources .middle-content .Equipment_Table_Content .Resource_Line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  /* align-items: center; */
  padding: 0.3rem 0;
  font-family: Verdana, sans-serif;
  font-size: 12px;
  color: #000
}

.Equipment_Resources .middle-content .Equipment_Table_Content .Resource_Line .Resource_Check,
.Equipment_Resources .middle-content .Equipment_Table_Content .Resource_Line .Resource_Compactors {
  width: 20%;
  text-align: center;
}

.Equipment_Resources .middle-content .Equipment_Table_Content .Resource_Line .Resource_Check,
.Equipment_Resources .middle-content .Equipment_Table_Content .Resource_Line .Resource_Compactors_Red {
  width: 20%;
  color: red;
  text-align: center;
}

.Equipment_Resources .middle-content .Equipment_Table_Content .Resource_Line .Resource_SubCategory {
  width: 60%;
  text-align: center;
}

.Equipment_Resources .middle-content .Equipment_Table_Header {
  display: flex;
}

.Equipment_Resources .middle-content .Equipment_Table_Header .header_item {
  background: #fff;
  padding: 10px 5px;
  border-bottom: 1px solid #e4e4e4;
  font-size: 18px;
  width: 20%;
}

.Equipment_Resources .middle-content .Subcategory_Name {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  color: #fff;
  background-color: #003F2D;
  padding: 5px 20px;
}

.Equipment_Resources .middle-content .Subcategory_Qty_Wrapper {
  width: 20%;
  text-align: center;
}

.Equipment_Resources .middle-content .Subcategory_Qty {
  width: 30px;
  text-align: center;
  padding: 5px;
}


.Equipment_Resources .middle-content .Subcategory_Qty:focus {
  outline: none;
}

.Equipment_Resources .middle-content .SubCategory_Input {
  background-color: #003F2D;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 1px;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.Equipment_Resources .middle-content .SubCategory_Input .Subcategory_Name {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  color: #fff;
  width: 80%;
}

.Equipment_Resources .middle-content .Buttons_Wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}

.Equipment_Resources .middle-content .Cancel_Buttons_Wrap {
  display: flex;
  width: auto;
}

.Equipment_Resources .Buttons_Wrap .Save {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Equipment_Resources .Buttons_Wrap .Save:disabled {
  background-color: #d8d8d8;
  border: 2px solid #d8d8d8;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: not-allowed;
}

.Equipment_Resources .Cancel_Buttons_Wrap .Cancel {
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Equipment_Resources .Cancel_Buttons_Wrap .Undo {
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Equipment_Resources .middle-content .Header_Information {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border: 2px solid #e4e4e4;
  margin-bottom: 1rem;
  padding: 5px;
}

.Equipment_Resources .middle-content .Header_Information .header_item {
  max-width: 250px;
  font-family: Verdana, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}