@keyframes blink {
  50% {
    border-color: red;
  }
}

.Day {
  display: flex;
  flex-direction: column;
  width: 274px;
  max-width: 274px;
  height: 250px;
  border: 1px solid #e4e4e4;
}

.Day.Orange_Border.Schedule_Locked {
  border: 6px solid #107418;
  cursor: no-drop;
}

.Day.Schedule_Locked {
  border: 6px solid #107418;
  cursor: no-drop;
}

.Day.Locked {
  background-color: #d3d3d3;
  cursor: no-drop;
}

.Day.Locked.Schedule_Locked {
  border: 6px solid #107418;
  cursor: no-drop;
}

.Day .Day_Header {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  padding-top: 1px;
  width: 100%;
}

.Day .Day_Header .Day_Status {
  font-size: 12px;
  background-color: transparent;
  border: 1px solid #003F2D;
  cursor: pointer;
  color: #003F2D;
}

.Day.Orange_Border {
  border: 4px solid rgb(245, 123, 32, 0.6);
}

.Day .Day_Header .Add_Item {
  border-radius: 50px;
  border: 1px solid red;
  background-color: red;
  padding: 3px 5px;

}

.Day .Day_Header .Add_Item i {
  color: #fff;
}

.Day .Day_Content {
  overflow-y: auto;
}

@media(max-width: 1680px) {
  .Day {
    width: 240px;
    max-width: 240px;
  }
}