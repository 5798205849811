.Add_Location {
  width: 30%;
  height: 20%;
  position: absolute;
  top: 50%;
  left: 34%;
  border: 1px solid #e4e4e4;
  background-color: #f3f3f3;
}

.Add_Location .New_Crew_Title {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.Add_Location .Wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.Add_Location .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Add_Location .Wrap img {
  height: auto;
  width: auto;
  max-width: 30px;
}

.Add_Location .LocationCreated_Body {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.Add_Location .LocationCreated_Body input {
  height: 33px;
  border: none;
  width: 100%;
  margin-bottom: 1rem;
}

.Add_Location .LocationCreated_Body .button-location {
  background-color: white;
  color: black;
  border: 2px solid #073f2d;
  border-radius: 4px;
  padding: 10.5px 7px;
  transition: 0.3s;
}

.Add_Location .LocationCreated_Body .button-location:hover {
  transition: 0.3s;
  background-color: #073f2d;
  color: white;
}

.Add_Location .LocationCreated_Body  .button-location:disabled {
  background-color: #d8d8d8;
  border: 2px solid #d8d8d8;
  color: #000;
  cursor: not-allowed;
}