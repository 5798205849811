.QtyError.Add_Crew {
    width: 200px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
}
.ErrorMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    width: 100%;
}
.ErrorActionHolder {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}
.ErrorSign {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 100%;
    background: red;
    position: relative;
    border: 1px solid black;
}
.Arrow1 {
    width: 30px;
    background-color: white;
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    left: calc(50% - 15px);
    transform: rotate(45deg);
    transform-origin: center;
    border-radius: 3px;
}
.Arrow2 {
    width: 30px;
    background-color: white;
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    left: calc(50% - 15px);
    transform: rotate(-45deg);
    transform-origin: center;
    border-radius: 3px;
}
.QtyError button{
    width: 100px;
}