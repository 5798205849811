.Users_Management {
  width: calc(100% - 1100px);
  min-width: 600px;
  height: auto;
  padding-bottom: 40px;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: 0 auto;
  overflow: hidden;
}

.Users_Management_Header {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Users_Management_Header .Date_Wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Users_Management_Header .Date_Wrap img {
  height: auto;
  width: auto;
  max-width: 40px;
}

.Users_Management_Header .Title {
  font-size: 14px;
  font-family: Verdana, sans-serif;
  width: auto;
  display: inline-block;
  padding-left: 5px;
}

.Users_Management_Header .Close {
  width: auto;
  max-width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.Users_Management .Table {
  display: block;
  width: 90%;
  height: 150px;
  margin-top: 5px;
  overflow: auto;
  margin: 0 auto;
  border: 1px solid #e4e4e4;
  background-color: #fff;
}

.Users_Management .Table .Table_Header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
}

.Users_Management .Table .Table_Header .Header_Item {
  width: 33.3%;
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000;
  text-align: center;
}

.Users_Management .Table .Table_Content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: 1px solid #e4e4e4;
}

.Users_Management .Table .Table_Content .Row {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e4e4e4;
  padding: 0.5rem 0;
  background-color: #fff;
}

.Users_Management .Table .Table_Content .Row .Row_Item {
  width: 33.3%;
  text-align: center;
  padding: 0 2px;
  font-size: 11px;
  color: #747474;
}

.Users_Management .Add_Super {
  display: block;
  width: 90%;
  margin: 20px auto;
  padding: 10px;
  padding-top: 20px;
  border: 1px solid #e4e4e4;
}

.Users_Management .Add_Super .Wrap_Inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Users_Management .Add_Super input {
  width: 45%;
  height: 35px;
  font-size: 14px;
  padding-left: 5px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
}

.Users_Management .Table .Table_Content .Row .Disable_User_Button {
  text-align: center;
  padding: 0 2px;
  font-size: 11px;
  color: #f57b20;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
}

.Users_Management .Add_Super .Add_Super_Button {
  display: flex;
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 auto;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  margin-top: 10px;
  cursor: pointer;
}

.Users_Management .Add_Super .Add_Super_Button:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s;
}