.Add_Crew {
    width: auto;
    height: auto;
    border: 1px solid #e4e4e4;
    background-color: #f3f3f3;
    margin: 0 auto;
}
.New_Crew_Title {
    font-size: 14px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 5px;
}

.New_Crew_Title div:last-child{
    cursor: pointer;
}

.Add_Crew .Add_Crew_Body {
  padding: 1rem 1rem;
}

.Add_Crew_Heading {
    padding: 1.3rem 0;
    font-size: 18px;
    color: #003F2D;
    width: auto;
    text-align: center;
}
.New_Crew_Line {
    display: flex;
    margin: 20px 0;
    padding: 0 30px;
}
.New_Crew_Line > div:first-child {
    width: 150px;
}
.New_Crew_Line input {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e4e4e4;
    height: 25px;
}

.New_Crew_Action_Holder {
    display: flex;
    margin-top: 50px;
    padding: 10px;
    justify-content: space-between;
}

  .New_Crew_Action_Holder .Button_Cancel{
    width: 85px;
    border: 2px solid #073f2d;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;    
}

.New_Crew_Action_Holder .Button_Cancel:hover{
    transition: 0.3s;
    background-color: #073f2d;
    color: #fff;
  }

  .New_Crew_Action_Holder .Button_Add{
    width: 85px;
    border: 2px solid #f57b20;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;    
}

.New_Crew_Action_Holder .Button_Add:hover {
    transition: 0.3s;
    background-color: #f57b20;
    color: #fff;
  }

  .New_Crew_Action_Holder .Button_Add:disabled {
    transition: 0.3s;
    background-color: #e4e4e4;
    border: 2px solid #d6d6d6;
    cursor:not-allowed;
  }

.Add_Crew .New_Crew_Title {
    background-color: #fff;
    display: flex;
    align-items: center;
}
  
.Add_Crew .Wrap {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
}

.Add_Crew .Wrap .Title {
    font-size: 14px;
    color: #5d5d5d;
    cursor:auto;
    padding: 0.5rem;
}

.Add_Crew .Wrap img {
    height: auto;
    width: auto;
    max-width: 30px;
}