.DeallocateAllResourcesFromDay {
  width: 300px;
  height: 180px;
  background-color: #f0f0f0;
  border: 1px solid gray;
}
.DeallocateAllResourcesFromDay .ErrorMessage {
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.DeallocateAllResourcesFromDay .ErrorActionHolder {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.Deallocate_Resources_Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.2rem;
}

.DeallocateAllResourcesFromDay .fa-exclamation-circle {
  color: red;
}

.DeallocateAllResourcesFromDay .ErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  padding-top: 2rem;
}

.DeallocateAllResourcesFromDay .ErrorText {
  font-size: 0.9rem;
  padding-top: 1rem;
}

.DeallocateAllResourcesFromDay .Deallocate_Resources_Title {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.DeallocateAllResourcesFromDay .Wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.DeallocateAllResourcesFromDay .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.DeallocateAllResourcesFromDay .Wrap img {
  height: auto;
  width: auto;
  max-width: 30px;
}