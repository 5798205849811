.Full_Day_Preview_History .Select_Days {
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-around;
  background-color: #f57b20;
  color: #fff;
}

.Full_Day_Preview_History .Full_Day_Preview {
  width: 100%;
}