.Job_Map {
  width: 30%;
  height: auto;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: 0 auto;
  overflow: hidden;
}

.Job_Map .JobMap_Header {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Job_Map .JobMap_Header .Date_Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Job_Map .JobMap_Header .Date_Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Job_Map .JobMap_Header .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Job_Map .middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;
  align-items: center;
}

.Job_Map .middle-content .Wrap_Inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  overflow: hidden;
}

.Job_Map .middle-content .Wrap_Inputs a.link {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.Job_Map .middle-content .Wrap_Inputs .Link_Input {
  width: 80%;
  height: 25px;
  margin-top: 10px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
}

.Job_Map .middle-content .Buttons_Wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}


.Job_Map .Buttons_Wrap .Save {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Job_Map .Buttons_Wrap .Save:disabled {
  background-color: #d8d8d8;
  border: 2px solid #d8d8d8;
  cursor: not-allowed;
}

.Job_Map .Buttons_Wrap .Cancel {
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.Job_Map .Buttons_Wrap .Cancel:disabled {
  background-color: #d8d8d8;
  border: 2px solid #d8d8d8;
  cursor: not-allowed;
}

.Job_Map .middle-content .Menu_Calendar {
  z-index: 3;
  background-color: #fff;
  border-radius: 2px;
  padding-left: 0;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0px 2px 10px #999999;
}

.Job_Map .middle-content .Menu_Calendar .Menu_Icons {
  display: flex;
  align-items: center;
  padding: 0.8rem;
}

.Job_Map .middle-content .Menu_Calendar .Menu_Icons button {
  font-family: Verdana, sans-serif;
  font-size: 13px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}