.calendar_paving_wrap {
  width: 100%;
  display: grid;
}

.calendar_paving_header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* max-width: 1024px; */
  width: 100%;
}

.calendar_paving {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* max-width: 1024px; */
  width: 100%;
}

.calendar_paving_header .header_item {
  display: flex;
  flex-direction: column;
  width: 274px;
  max-width: 274px;
  border: 1px solid #e4e4e4;
  align-items: center;
  color: #003F2D;
  padding: 5px 0;
}

@media(max-width: 1680px) {
  .calendar_paving_header { 
    /* max-width: 1024px; */
  }
  .calendar_paving {
    /* max-width: 1024px; */
  }
  .calendar_paving_header .header_item {
    width: 240px;
    /* max-width: 240px; */
  }
}

