.Edit_Resource {
  width: 75%;
  background-color: #f3f3f3;
  border: 1px solid #e4e4e4;
  margin: 0 auto;
  overflow-y: auto;
}

.Edit_Resource .Edit_Resource_Header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Edit_Resource .Edit_Resource_Header .Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Edit_Resource .Edit_Resource_Header .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Edit_Resource .Edit_Resource_Header .Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Edit_Resource .Middle_Content .Header {
  display: flex;
  padding: 1rem 0;
}

.Edit_Resource .Middle_Content .Content {
  display: flex;
  width: 100%;
}

.Edit_Resource .Middle_Content .Header .header_item {
  width: 25%;
  text-align: center;
  font-size: 12px;
}

.Edit_Resource .Middle_Content .Content .Operation_Type,
.Edit_Resource .Middle_Content .Content div,
.Edit_Resource .Middle_Content .Content input {
  width: calc(25% - 20px);
  text-align: center;
  margin: 0 10px;
}

.Edit_Resource .Button_Wrap {
  width: 100%;
  display: block;
  text-align: center;
  margin: 1rem 0;
}

.Edit_Resource .Button_Wrap button {
  margin: 0 auto;
  text-align: center;
  display: flex;
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  margin-top: 0.5rem;
  cursor: pointer;
}

.Edit_Resource .Button_Wrap button:disabled {
  background-color: grey;
  color: #fff;
  border-color: grey;
  cursor: not-allowed;
}

.Edit_Resource .Button_Wrap .Disabled_Button_Message {
  font-size: 13px;
  margin-top: 1rem;
}