@keyframes bounceRight {

  0% {
    -webkit-transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0.4em);
  }

  /* 100% {
    -webkit-transform: translateX(0.4em);
  } */
}

@keyframes bounceLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-0.4em);
  }

  /* 100% {
    -webkit-transform: translateX(-0.4em);
  } */
}

@keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(0.4em);
  }

  /* 100% {
    -webkit-transform: translateX(-0.4em);
  } */
}

@keyframes bounceDown {
  0% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-0.4em);
  }
}

.Full_Day_Preview {
  width: calc(100% - 15px);
  background-color: #f4f4f4;
  overflow-x: hidden;
  height: calc(100% - 130px);
  overflow-y: hidden;
}

.Full_Day_Preview .Modal_Body {
  display: flex;
  height: 100%;
  max-height: 760px;
  margin-left: 15px;
}

.Full_Day_Preview .Modal_Bar {
  width: 100%;
  background-color: #f3f3f3;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.Full_Day_Preview .Modal_Bar .Title {
  font-size: 14px;
  color: #5d5d5d;
  padding-left: 5px;
}

.Full_Day_Preview .Modal_Bar .Date_Wrap {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.Full_Day_Preview .Modal_Bar .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 35px;
}

.Full_Day_Preview .Modal_Bar .Close {
  width: auto;
  display: block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.Full_Day_Preview .Days_Left_Header {
  width: 100%;
  height: 662px;
  max-width: 100px;
  margin-top: 77px;
  border-right: 1px solid #e4e4e4;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Full_Day_Preview .Wrap_Right_Side {
  display: inline-flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 760px;
}

.Full_Day_Preview .Wrap_Right_Side .SuperIntendent_Sheet {
  width: auto;
  height: 739px;
  max-height: 739px;
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: 1px solid #e4e4e4;
}

.Full_Day_Preview .Wrap_Right_Side .SuperIndendent_Name {
  color: #fff;
  background-color: rgba(7, 63, 45, 1);
  width: auto;
  max-width: 300px;
  padding: 5px;
  text-align: center;
}

.Full_Day_Preview .ExpandableSheet.Expanded .Wrap_Header {
  width: auto;
  margin-left: 99px;
}

.Full_Day_Preview .ExpandableSheet.Collapsed .Wrap_Header {
  width: auto;
}

.Full_Day_Preview .Wrap_SheetHeader {
  width: auto;
  display: flex;
}


.Full_Day_Preview .Days_Left_Header .date {
  width: 100px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  padding-top: 5px;
}

.Full_Day_Preview .Days_Left_Header .date:nth-child(2n) {
  background-color: #e4e4e4;
}

.Sheet_Row {
  height: 30px;
  display: flex;
  width: 100%;
}

.Sheet_Row_Green {
  border: 2px solid #107418;
}

.Sheet_Row:nth-child(2n) {
  height: 30px;
  display: flex;
  width: 100%;
  background-color: #e4e4e4;
}

.Full_Day_Preview .Wrap_Right_Side .SuperIntendent_Sheet .FullBody {
  display: flex;
  height: 662px;
  max-height: 662px;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Full_Day_Preview .Wrap_Right_Side .SuperIntendent_Sheet .FullBody .Body {
  width: 100%;
}

.Full_Day_Preview .Wrap_Right_Side .SuperIntendent_Sheet .FullBody .Body .Body_Row {
  background-color: #f4f4f4;
}

.Full_Day_Preview .Wrap_Right_Side .SuperIntendent_Sheet .Body .Body_Row:nth-child(2n) {
  background-color: #e4e4e4;
}

.Full_Day_Preview .Scroll_Horizontally_Buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
}

.Full_Day_Preview .Scroll_Horizontally_Buttons button {
  border: 1px solid #e7e7e7;
  border-radius: 50px;
  background-color: transparent;
  opacity: 0.8;
  transition: 0.3s ease-in-out;
}

.Full_Day_Preview .Scroll_Horizontally_Buttons .fa-chevron-right {
  color: #073f2c;
  animation: bounceRight 1.5s 10 alternate ease-in-out;
  font-size: 1.5rem;
}

.Full_Day_Preview .Scroll_Horizontally_Buttons .fa-chevron-left {
  color: #073f2c;
  animation: bounceLeft 1.5s 10 alternate ease-in-out;
  font-size: 1.5rem;
}

.Full_Day_Preview .Scroll_Vertically_Buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0.5rem 0;
}

.Full_Day_Preview .Scroll_Vertically_Buttons button {
  border: 1px solid #e7e7e7;
  border-radius: 50px;
  background-color: transparent;
  opacity: 0.8;
  transition: 0.3s ease-in-out;
}

.Full_Day_Preview .Scroll_Vertically_Buttons .fa-chevron-up {
  color: #073f2c;
  animation: bounceUp 1.5s 10 alternate ease-in-out;
  font-size: 1.5rem;
}

.Full_Day_Preview .Scroll_Vertically_Buttons .fa-chevron-down {
  color: #073f2c;
  animation: bounceDown 1.5s 10 alternate ease-in-out;
  font-size: 1.5rem;
}

.Full_Day_Preview~.Button_Container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.Full_Day_Preview~.Button_Container button {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  margin: 10px 5px;
  cursor: pointer;
}

.Full_Day_Preview~.Button_Container button:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s all ease-in-out;
}

.Full_Day_Preview~.Button_Container button:disabled {
  background-color: grey;
  color: #fff;
  border-color: grey;
  cursor: not-allowed;
}