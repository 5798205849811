.Equipment_List_Management {
  width: 100%;
  max-width: 900px;
  min-width: 600px;
  height: auto;
  max-height: 800px;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: auto auto;
  overflow: auto;
}

.Equipment_List_Management_Header {
  padding: 4px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Equipment_List_Management_Header .Date_Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1.7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Equipment_List_Management_Header .Date_Wrap .Title {
  overflow: hidden;
  white-space: nowrap;
  color: blue;
  cursor: pointer;
  font-size: 14px;
}

.Equipment_List_Management_Header .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Equipment_List_Management .middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  overflow: hidden;
}

.Equipment_List_Management .middle-content .List_Header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
  border: 1px solid gray;
  border-bottom: none;
}

.Equipment_List_Management .middle-content .header_item {
  text-align: center;
  padding: 0 2px;
  width: 12.5%;
  max-width: 112px;
  font-size: 11px;
  /* color: #747474; */
}

.Equipment_List_Management .List_Header .header_item {
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000;
  justify-content: center;
  align-items: center;
  display: inherit;
}

.Equipment_List_Management .List_Table {
  background-color: #fff;
  border: 1px solid gray;
  height: 400px;
  max-height: 400px;
  overflow: auto;
}

.Equipment_List_Management .List_Table .Row_List {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
}

.Equipment_List_Management .Row_List.New_Line {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.Equipment_List_Management .Row_List.New_Line .header_item {
  width: auto;
  height: 35px;
  font-size: 14px;
  padding-left: 5px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
}

.Actions_Holder_Equipment_List_Management {
  display: flex;
  padding-bottom: 1.5rem;
}

.Actions_Holder_Equipment_List_Management .buttons {
  display: inherit;
  justify-content: space-around;
  width: 100%;
}

.Actions_Holder_Equipment_List_Management .buttons .Button_Cancel {
  display: flex;
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 auto;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  margin-top: 10px;
  cursor: pointer;
}

.Actions_Holder_Equipment_List_Management .buttons .Add_Resources {
  display: flex;
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 auto;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  margin-top: 10px;
  cursor: pointer;
  width: auto;
}

.Actions_Holder_Equipment_List_Management .buttons button:disabled {
  background-color: grey;
  color: #fff;
  border-color: grey;
  cursor: not-allowed;
}

.Equipment_List_Management button.Delete_Button {
  width: 100%;
  color: #f57b20;
  background-color: transparent;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-transform: lowercase;
  font-size: 0.8rem;
}

.Equipment_List_Management button.Button.Refresh_Resources {
  display: flex;
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  margin-top: 10px;
  cursor: pointer;
  width: auto;
  margin-right: auto;
  margin-left: 1rem;
}

.Equipment_List_Management button.Button.Refresh_Resources:disabled {
  cursor:not-allowed;
  border: 2px solid #e4e4e4;
  background-color: #e4e4e4;
}