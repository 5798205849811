.Paving_Planner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(7, 64, 45, 0.85);
}

.Paving_Planner .Wrap {
  background-color: #fff;
  width: auto;
  padding: 3rem;
  display: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Paving_Planner .Button_Container {
  padding-top: 2rem;
  display: inherit;
  flex-direction: column;
}

.Paving_Planner .Button_Container button {
  background-color: #07402d;
  border: 2px solid #07402d;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 0.2rem;
  font-family: Verdana, sans-serif;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  margin: 10px 5px;
  cursor: pointer;
}

.Full_Day_Preview~.Button_Container button:hover {
  background-color: #fff;
  color: #07402d;
  transition: 0.3s all ease-in-out;
}

.Full_Day_Preview~.Button_Container button:disabled {
  background-color: grey;
  color: #fff;
  border-color: grey;
  cursor: not-allowed;
}