.Add_Resources {
  width: 70%;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  margin: 0 auto;
  overflow-y: auto;
}

.Add_Resources .Add_Resources_Header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Add_Resources .Add_Resources_Header .Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Add_Resources .Add_Resources_Header .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Add_Resources .Add_Resources_Header .Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Add_Resources .Wrap_Content {
  display: flex;
  justify-content: center;
}

.Add_Resources .Wrap_Content .Resources .Header {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
  border: 1px solid gray;
}

.Add_Resources .Menu_Options {
  width: 30%;
  border: 1px solid #e4e4e4;
  overflow-y: auto;
  background-color: #fff;
  vertical-align: top;
  font-family: Verdana, sans-serif;
  font-size: 14px;
  color: #000;
}

.Add_Resources .Menu_Options .Table_Line {
  border-bottom: 1px solid #d3d3d3;
}

.Add_Resources .Table_Line.Selected_Line {
  background-color: #f57b20;
  color: #fff;
}

.Add_Resources .Resources {
  width: calc(70% - 15px);
  display: inherit;
  flex-direction: column;
  padding: 0px 5px;
}

.Add_Resources .Resource .Header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #b9b9b9;
  margin-bottom: 0.3rem;
}

.Add_Resources .Resources .Header .item {
  width: 33%;
  text-align: center;
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000;
}

.Add_Resources .Resource div {
  width: 33%;
}

.Add_Resources .Resources .Resources_List {
  height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.Add_Resources .Resource {
  display: inherit;
  width: 100%;
  text-align: center;
  padding: 0.2rem 0;
  padding-bottom: 0.2rem;
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000;
}

.Add_Resources .Resource.Disabled {
  color: #7c7a7a;
}

.Add_Resources .Resource:nth-child(2n) {
  background-color: #e7e7e7;
}

.Add_Resources .Resource:nth-child(2n) .Edit_Values {
  background-color: #e7e7e7;
}

.Add_Resources .Resource .Edit_Values {
  width: 33%;
  text-align: center;
  margin: 0 auto;
  color: #f57b20;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.Add_Resources .Button_Menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.Add_Resources .Button {
  width: auto;
  border: none;
  padding: 0.4rem 1.2rem;
  margin: auto;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-family: Verdana, sans-serif;
  border-radius: 5px;
  cursor: pointer;
}

.Add_Resources .Button:disabled {
  cursor: none;
}

.Add_Resources .Button.Add_Resource {
  background-color: #f57b20;
  border: 2px solid #f57b20;
}

.Add_Resources .Button.Add_Resource:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s ease-in-out;
}

.Add_Resources .Button.Delete_Resource {
  background-color: #073f2d;
  border: 2px solid #073f2d;
}

.Add_Resources .Button.Delete_Resource:hover {
  background-color: #fff;
  color: #073f2d;
  transition: 0.3s ease-in-out;
}

.Header .col1 .button.button-resources {
  width: 50px;
  justify-content: center;
  align-items: center;
  border: 2px solid #073f2d;
  border-radius: 4px;
  background-color: #fff;
  transition: 0.3s ease-in-out;
}

.Header .col1 .button.button-resources:hover {
  background-color: #073f2d;
  transition: 0.3s ease-in-out;
}

.Header .col1 .button.button-resources i {
  color: #073f2e;
}

.Header .col1 .button.button-resources:hover i {
  color: #ffff;
  transition: 0.3s ease-in-out;
}