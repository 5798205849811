.LockedDaysWhileWorking {
  width: auto;
  height: auto;
  border: 1px solid #e4e4e4;
  background-color: #f3f3f3;
  margin: 0 auto;
}

.LockedDaysWhileWorking .Title {
  font-size: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 5px 5px;
}

.ErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ErrorActionHolder {
  padding-right: 10px;
  text-align: center;
  padding: 15px;
}

.NothingToSave button {
  width: 100px;
}
