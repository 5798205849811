.Edit_Trucking_Resource {
  width: 80%;
  height: auto;
  background-color: #f4f4f4;
  border: 1px solid gray;
  margin: 0 auto;
  overflow: hidden;
}

.Trucking_Header {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Trucking_Header .Date_Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Trucking_Header .Date_Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Trucking_Header .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Trucking_Title {
  display: flex;
  justify-content: flex-start;
  padding: 1.3rem 0;
  font-size: 18px;
  color: #003F2D;
}

.Title {
  font-size: 14px;
  width: auto;
  display: inline-block;
  padding-left: 0px;
}

.Close {
  width: auto;
  max-width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.Edit_Trucking_Resource .middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 1.5rem 0;
}

.Edit_Trucking_Resource .middle-content .Wrap_Inputs_Side {
  flex-direction: column;
  width: 75%;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

.Edit_Trucking_Resource .middle-content .Wrap_Row {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
  align-items: center;
}

.Edit_Trucking_Resource .middle-content .Wrap_Row .Row {
  display: block;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  height: auto;
  position: relative;
}

.Edit_Trucking_Resource .middle-content .Wrap_Row .Row .Combined {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  width: 100%;
}

.Edit_Trucking_Resource .left_side {
  width: 100%;
}

.Edit_Trucking_Resource .right_side {
  width: 100%;
  padding: 0 1rem;
}

.Edit_Trucking_Resource .Table_Header {
  background: #fff;
  padding: 2px 5px;
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.Table_Line:nth-child(2n-1) {
  background-color: #d3d3d3;
}

.Table_Line:nth-child(2n) {
  background-color: #fff;
}

.Table_Line {
  border-bottom: 1px solid gray;
  padding: 3px 5px;
}

.Table_Line.Selected_Line {
  background-color: #f57b20;
}

.Trucking_Resources {
  display: block;
  width: 75%;
  margin-top: 5px;
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid #e4e4e4;
}

.Trucking_Table {
  background-color: #fff;
  border-top: 1px solid #e4e4e4;
  height: 275px;
  overflow: auto;
}

.Trucking_Table_Header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.7rem 0;
  background-color: #fff;
}

.Trucking_Resource_Line {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e4e4e4;
  padding: 0.5rem 0;
  background-color: #fff;
}

.Add_Trucking_Holder {
  width: calc(30% - 10px);
  display: inline-block;
  margin-top: 5px;
  padding-left: 5px;
}

.Edit_Trucking_Resource .Actions_Holder {
  padding: 1rem;
  width: 100%;
}

.Flex_Corect {
  display: flex;
  justify-content: space-between;
}

.Action_Holder {
  padding: 1rem;
  margin: 0 auto;
  display: block;
}

.Action_Holder .Add_Truck_Resources {
  padding: 0.4rem 1.5rem;
  font-size: 14px;
  background-color: #f57b20;
  color: #fff;
  border: 2px solid #f57b20;
  transition: 0.3s;
}

.Action_Holder .Add_Truck_Resources:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s;
}

.Edit_Trucking_Resource .middle-content .left_side .Row {
  padding-bottom: 5px;
}

.Edit_Trucking_Resource .middle-content .left_side .Row:last-child {
  padding-bottom: 0;
}

.Edit_Trucking_Resource .middle-content .Row * {
  display: inline-block;
  position: relative;
}

.Edit_Trucking_Resource .middle-content .Row_Usage {
  font-size: 14px;
  text-align: justify;
}

.Edit_Trucking_Resource .middle-content .Row .Combined select.dropdown {
  width: 100%;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  border: 1px solid #e4e4e4;
  position: relative;
}

.Edit_Trucking_Resource .middle-content .Row select.dropdown {
  width: 100%;
  height: 33px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  border: 1px solid #e4e4e4;
  position: relative;
  padding-left: 0.2rem;
  font-size: 14px;
}

.Edit_Trucking_Resource .middle-content .Row .Wrap_Select {
  position: relative;
  width: 100%;
}

.Edit_Trucking_Resource .middle-content .Row .Combined .Wrap_Select {
  position: relative;
  width: 55%;
}

.Edit_Trucking_Resource .middle-content .Row .Wrap_Select::after {
  content: "\f0dc";
  font-family: "Font Awesome 5 Free";
  width: 5px;
  height: 5px;
  font-size: 15px;
  position: absolute;
  right: 12px;
  top: 10px;
  font-weight: 900;
  color: #f57b20;
}

.Edit_Trucking_Resource .middle-content .Static_table .Row input {
  width: 30px;
  height: 25px;
}

.Edit_Trucking_Resource .middle-content .right_side .Row input {
  background-color: #f3f3f3;
  border-radius: 2px;
  width: 100%;
  font-size: 14px;
}

.Edit_Trucking_Resource .middle-content .Wrap_Row .Row .Combined .input-container {
  width: 40%;
  height: 25px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
}

.Edit_Trucking_Resource .middle-content .Wrap_Row .right_side textarea {
  height: 100%;
  max-height: 185px;
  border-radius: 0;
  border: 1px solid #e4e4e4;
  width: 100%;
  font-size: 14px;
}

.Edit_Trucking_Resource .middle-content .Wrap_Row .Row .Combined input {
  width: 100%;
}

.Edit_Trucking_Resource .middle-content .Static_table input.inactive {
  background-color: lightgrey;
  color: #7b7a7a;
}

.Edit_Trucking_Resource .middle-content .Static_table input.active {
  background-color: fff;
  font-weight: bold;
}

.header_item_small {
  padding: 0 2px;
  width: 5%;
  max-width: 62px;
}

.Edit_Trucking_Resource .middle-content .header_item {
  text-align: center;
  padding: 0 2px;
  width: 13.5%;
  max-width: 162px;
  font-size: 11px;
  color: #747474;
}

.Edit_Trucking_Resource .middle-content .Trucking_Table_Header .header_item {
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #000
}

.Edit_Trucking_Resource .Edit_Resource_Button {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: Verdana, sans-serif;
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  transition: 0.3s;
}

.Edit_Trucking_Resource .Edit_Resource_Button:hover {
  background-color: #fff;
  color: #f57b20;
  transition: 0.3s;
}

.Edit_Trucking_Resource .Edit_Resource_Button:disabled {
  background-color: grey;
  color: #fff;
  border-color: grey;
  cursor: not-allowed;
}