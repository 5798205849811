.Manage_Paving_Main_Screen {
	width: auto;
	min-width: 600px;
	height: calc(100% - 50px);
	max-height: 800px;
	background-color: #f4f4f4;
	border: 1px solid gray;
	margin: auto auto;
	overflow: auto;
}

.Paving_Header {
	padding: 4px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
}

.Paving_Header .Date_Wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-left: 1.7rem;
	/* padding-right: 1rem; */
	padding-top: 1rem;
	padding-bottom: 1rem;
	/* padding: 1rem; */
}

.Paving_Header .Date_Wrap .Title {
	overflow: hidden;
	white-space: nowrap;
	color: blue;
	cursor: pointer;
	font-size: 14px;
}

.Paving_Header .Date_Wrap img {
	height: 100%;
	width: 100%;
	max-width: 160px;
}

.Paving_Title {
	display: flex;
	justify-content: flex-start;
	padding: 1rem 0;
	font-size: 18px;
	color: #003f2d;
}

.Manage_Paving_Main_Screen .middle-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
}

.Manage_Paving_Main_Screen .middle-content .Wrap_Inputs_Side {
	flex-direction: column;
	width: 100%;
	padding-left: 2rem;
	justify-content: center;
	display: flex;
	margin: 0 auto;
}

.Manage_Paving_Main_Screen .middle-content .Equipment_Paving_Second {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	height: auto;
}

.Manage_Paving_Main_Screen .middle-content .Equipment_Paving_Second .Row {
	display: block;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	font-size: 14px;
	height: auto;
	position: relative;
}

.Manage_Paving_Main_Screen .left_side {
	width: 100%;
}

.Manage_Paving_Main_Screen .right_side {
	width: 100%;
	padding: 0 1rem;
}

.Manage_Paving_Main_Screen .middle-content .left_side .Row {
	padding-bottom: 0;
}

.Manage_Paving_Main_Screen .middle-content .left_side .Row:last-child {
	padding-bottom: 0;
}

.Manage_Paving_Main_Screen .middle-content .Row * {
	display: inline-block;
	position: relative;
}

.Manage_Paving_Main_Screen .middle-content .Static_table .Row input {
	width: 30px;
	height: 25px;
}

.Manage_Paving_Main_Screen .middle-content .right_side .Row input {
	background-color: #f3f3f3;
	border-radius: 2px;
	width: 100%;
	font-size: 14px;
}

.Manage_Paving_Main_Screen .middle-content .Row .Combined .input-container {
	width: 40%;
	height: 25px;
	vertical-align: middle;
	background-color: #fff;
	border-radius: 2px;
	border: 1px solid #e4e4e4;
}

.Manage_Paving_Main_Screen .middle-content .Row .Combined .Wrap_Select {
	position: relative;
	width: 55%;
}

.Manage_Paving_Main_Screen .middle-content .Row .Wrap_Select {
	position: relative;
	width: calc(100% - 125px);
}

.Manage_Paving_Main_Screen .middle-content .Row .Wrap_Select::after {
	content: "\f0dc";
	font-family: "Font Awesome 5 Free";
	width: 5px;
	height: 5px;
	font-size: 15px;
	position: absolute;
	right: 12px;
	top: 6px;
	font-weight: 900;
	color: #f57b20;
}

.Manage_Paving_Main_Screen .middle-content .Row .CombinedCheckBox {
	display: flex;
	align-items: center;
	font-size: 14px;
	width: 100%;
}

.Manage_Paving_Main_Screen .Row .CombinedCheckBox .WrapCheckBox {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Manage_Paving_Main_Screen .Row .CombinedCheckBox .WrapCheckBox input[type="checkbox"] {
	width: 18px;
}

.Manage_Paving_Main_Screen .Row .WrapCheckBox {
	display: flex;
	align-items: center;
	width: auto;
}

.Manage_Paving_Main_Screen .Row .WrapCheckBox input[type="checkbox"] {
	width: 18px;
}

.Manage_Paving_Main_Screen .GrinderWrap {
	border: 1px solid gray;
	padding: 5px;
	margin-bottom: 5px;
}

.Manage_Paving_Main_Screen .Equipment_Paving_Main {
	width: 100%;
	position: relative;
	height: 88%;
	padding: 0.5rem 0.3rem;
	display: flex;
	margin: 0 auto;
}

.Actions_Holder_Paving {
	padding-right: 2em;
	padding-left: 2em;
	padding-bottom: 2em;
}

.Actions_Holder_Paving .Button_Cancel {
	float: right;
}

.Oil_Truck_Check {
	height: 100%;
	width: 100%;
}

.Equipment_Paving_Second input {
	width: calc(100% - 118px);
	height: 20px;
}

.Manage_Paving_Main_Screen .middle-content .Equipment_Paving_Second .Row {
	padding-bottom: 5px;
}

.Manage_Paving_Main_Screen .middle-content .Equipment_Paving_Second .Row .Tracking_Access_Link {
	position: relative;
	overflow: hidden;
	flex: 1;
	margin: 3px 5px;
	background-color: #003F2D;
	border: 2px solid #003F2D;
	color: #fff;
	font-size: 14px;
	padding: 0.4rem 1.2rem;
	margin-left: 15px;
	font-family: Verdana, sans-serif;
	transition: 0.3s;
	cursor: pointer;
}

.Manage_Paving_Main_Screen .middle-content .Equipment_Paving_Second .Row .Tracking_Access_Link:hover {
	background-color: #fff;
	color: #003F2D;
	transition: 0.3s;
}

.Manage_Paving_Main_Screen .middle-content .Row {
	height: auto;
}

.Manage_Paving_Main_Screen .middle-content .Wrap_Inputs_Side .Row .Combined {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	font-size: 14px;
	width: 100%;
}

.Manage_Paving_Main_Screen .middle-content .Row .Combined input {
	width: 20%;
	height: 33px;
}

.Manage_Paving_Main_Screen .middle-content .Row select.dropdown {
	width: 100%;
	height: 33px;
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	border: 1px solid #e4e4e4;
	position: relative;
	padding-left: 0.2rem;
	font-size: 14px;
}

.Manage_Paving_Main_Screen .middle-content .Row input {
	background-color: #fff;
	/* width: 67.5%; */
	border-radius: 2px;
	border: 1px solid #e4e4e4;
}

.Manage_Paving_Main_Screen .middle-content .Row input.disabled {
	background-color: #e4e4e4;
}

.Manage_Paving_Main_Screen .middle-content .Row_Usage {
	width: 100px;
	max-width: 115px;
	font-size: 12px;
	text-align: left;
	padding: 0 0.5rem 0 0.5rem;
}

.Manage_Paving_Main_Screen .Equipment_Resources_Table {
	border: 1px solid #e4e4e4;
	background-color: #fff;
	height: 100%;
	border-left: none;
	border-right: none;
	overflow: auto;
	width: 100%;
	max-height: 525px;
}

.Manage_Paving_Main_Screen .Equipment_Resources_Table .Paving_Resource_Name {
	width: 65.5%;
	padding: 3px 5px;
}

.Manage_Paving_Main_Screen .Equipment_Resources_Table .Resource_Check {
	width: 30px;
	padding-left: 5px;
	padding: 3px 5px;
}

.Manage_Paving_Main_Screen .Equipment_Resources_Table .Resource_Qu {
	width: 20%;
	padding-left: 0;
	padding: 0px 0px;
	overflow: hidden;
}

.Manage_Paving_Main_Screen .Equipment_Table_Header>div:nth-child(2) {
	width: 65.5%;
	padding: 5px 5px;
	border-right: none;
}

.Manage_Paving_Main_Screen .Equipment_Table_Header>div:nth-child(3) {
	width: 20%;
	padding: 5px 5px;
	border-right: none;
	text-align: center;
}

.Manage_Paving_Main_Screen .Equipment_Table_Header>div:nth-child(1) {
	width: 14.5%;
	padding: 5px 5px;
	border-right: none;
}

.Manage_Paving_Main_Screen .Equipment_Paving_Second input {
	width: 100%;
	height: 33px;
	background-color: #fff;
	border-radius: 2px;
	border: 1px solid #e4e4e4;
}

.Manage_Paving_Main_Screen .middle-content .Row .Combined select.dropdown {
	width: 100%;
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	border: 1px solid #e4e4e4;
	position: relative;
}

.Manage_Paving_Main_Screen .middle-content .Static_table .Row input {
	width: 30px;
	height: 25px;
}

.Manage_Paving_Main_Screen .middle-content .grinder {
	display: flex;
	width: auto;
	/* flex-wrap: wrap; */
	padding: 5px 0;
	align-items: center;
}

.Manage_Paving_Main_Screen .middle-content .Checkboxes {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.Manage_Paving_Main_Screen .middle-content .Checkboxes .WrapCheckBox {
	width: 50%;
}

.Manage_Paving_Main_Screen .middle-content .grinder .Row {
	width: 50%;
}

.Manage_Paving_Main_Screen .middle-content .Row.Notes input {
	width: 100%;
	height: 100px;
}

.Manage_Paving_Main_Screen .middle-content .grinder .Row_Usage {
	max-width: 30px;
	margin-right: 10px;
}

.Manage_Paving_Main_Screen .middle-content .grinder input {
	width: 35px;
}

.Paving_Before_Table {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	justify-content: space-between;
	align-items: flex-start;
}

.Manage_Paving_Main_Screen .middle-content .right_side .Row .Wrap_Select {
	width: 100%;
}

.Manage_Paving_Main_Screen .middle-content .right_side .Row {
	width: 100%;
	padding: 0.5rem 0.3rem;
}

.Manage_Paving_Main_Screen .right_side .Paving_Before_Table .WrapCheckBox .Wrap_Select .Row select.dropdown {
	width: 100%;
	height: 33px;
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	border: 1px solid #e4e4e4;
	position: relative;
	padding-left: 0.2rem;
	font-size: 14px;
}

.Paving_Before_Table .Paving_Table_Title {

	padding-top: 1.3rem;
	padding-left: 0.3rem;
	font-size: 18px;
	color: #003F2D;
	width: auto;
}

.Paving_Before_Table .buttons {
	width: auto;
}

.Actions_Holder_Paving .buttons .Button_Cancel {
	background-color: #003F2D;
	border: 2px solid #003F2D;
	color: #fff;
	font-size: 14px;
	padding: 0.4rem 1.2rem;
	margin: 0 0.2rem;
	font-family: Verdana, sans-serif;
	transition: 0.3s;
	cursor: pointer;
}

.Actions_Holder_Paving .buttons .Button_Cancel:hover {
	background-color: #fff;
	color: #003F2D;
	transition: 0.3s;
}

.Actions_Holder_Paving .buttons button.Add_Resources:disabled {
	background-color: grey;
	color: #fff;
	border-color: grey;
	cursor: not-allowed;
}

.Actions_Holder_Paving .buttons .Add_Resources {
	background-color: #f57b20;
	border: 2px solid #f57b20;
	color: #fff;
	font-size: 14px;
	padding: 0.4rem 1.2rem;
	margin: 0 0.2rem;
	font-family: Verdana, sans-serif;
	transition: 0.3s;
	cursor: pointer;
	width: auto;
}

.Actions_Holder_Paving .buttons .Add_Resources:hover {
	background-color: #fff;
	color: #f57b20;
	transition: 0.3s;
}

.red {
	border: 1px solid red;
	border-radius: 150px;
	line-height: 35px;
	width: 39px;
	height: 39px;
}

.locked {
  background-color: lightgrey;
  border-radius: 150px;
  line-height: 35px;
  width: 39px;
  height: 39px;
	cursor: not-allowed
}

.nonGeneratedDays {
  background-color: #eeeeee;
  border-radius: 150px;
  line-height: 35px;
  width: 39px;
  height: 39px;
	cursor: not-allowed
}

.flatpickr-day.flatpickr-disabled{
	color: rgb(72 72 72);
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
	fill: white;
}