.Manage_Crew_Main_Screen {
  width: 70%;
  /* height: 500px; */
  background-color: #f3f3f3;
  border: 1px solid #e4e4e4;
  margin: 0 auto;
  overflow-y: auto;
}
.Crew_Header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.Crew_Header .Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.Crew_Header .Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
}

.Crew_Header .Wrap img {
  height: 100%;
  width: 100%;
  max-width: 160px;
}

.Close {
  max-width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}
.Crew_Select {
  width: 30%;
  display: inline-block;
  border: 1px solid #e4e4e4;
  height: calc(100% - 80px);
  overflow-y: auto;
  margin-top: 20px;
  margin-left: 10px;
  background-color: #f3f3f3;
  vertical-align: top;
}
.Manage_Crew_Main_Screen .Table_Header {
  background: #fff;
  padding: 10px 5px;
  border-bottom: 1px solid #e4e4e4;
  font-size: 18px;
  color: #07402e;
}
.Manage_Crew_Main_Screen .Table_Line:nth-child(2n-1) {
  background-color: #fff;
}
.Manage_Crew_Main_Screen .Table_Line:nth-child(2n) {
  background-color: #fff;
}
.Manage_Crew_Main_Screen .Table_Line {
  border-bottom: 1px solid #e4e4e4;
  padding: 5px 5px;
  cursor: pointer;
  background-color: #fff;
  color: #747474;
}
.Manage_Crew_Main_Screen .Table_Line.Selected_Line {
  background-color: #f57b20;
  color: white;
  cursor: pointer;
}
.Crew_Resources {
  display: inline-block;
  width: calc(70% - 15px);
  margin-top: 20px;
  padding: 0px 5px;
}
.Crew_Name {
  font-size: 18px;
  color: #07402e;
  padding-bottom: 5px;
  padding-left: 5px;
}
.Manage_Crew_Main_Screen .Crews_Table {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  height: 390px;
  overflow: auto;
  margin-right: 5px;
}

.Manage_Crew_Main_Screen .Resources_Table {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  height: 390px;
  overflow: auto;
  margin-right: 5px;
}
.Manage_Crew_Main_Screen .Resource_Line:nth-child(2n-1) {
  background-color: #fff;
}
.Manage_Crew_Main_Screen .Resource_Line:nth-child(2n) {
  background-color: #fff;
}
.Manage_Crew_Main_Screen .Resource_Line {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e4e4e4;
  color: #747474;
  font-family: Verdana, sans-serif;
  align-items: center;
  padding: 0.3rem 0;
}
.Manage_Crew_Main_Screen .Resource_ID {
  width: 20%;
  padding: 3px 5px;
  border-right: 1px solid #e4e4e4;
}
.Manage_Crew_Main_Screen .Resource_Name {
  width: 70%;
  padding: 3px 5px;
  border-right: 1px solid #e4e4e4;
}
.Manage_Crew_Main_Screen .Resource_Qu {
  width: 10%;
  padding-left: 5px;
  padding: 3px 5px;
}
.Manage_Crew_Main_Screen .Add_Crew_Holder {
  width: 30%;
  display: inline-block;
  margin-top: 5px;
  padding-left: 5px;
}
.Manage_Crew_Main_Screen .Add_Delete_Resources_Holder {
  display: inline-block;
  width: calc(70% - 10px);
  margin-top: 5px;
  padding: 0px 5px;
}
.Manage_Crew_Main_Screen .Flex_Corect {
  display: flex;
  justify-content: space-between;
}

.Manage_Crew_Main_Screen .Actions_Holder {
  padding: 0.6rem 0;
}

.Manage_Crew_Main_Screen .Actions_Holder .Add_Crew_Button,
.Manage_Crew_Main_Screen .Actions_Holder .Delete_Resources,
.Manage_Crew_Main_Screen .Actions_Holder .Add_Edit_Resources {
  border: 2px solid #073f2d;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 20px;
  margin-left: 10px;
  cursor: pointer;

}

.Manage_Crew_Main_Screen .Actions_Holder .Add_Crew_Button,
.Manage_Crew_Main_Screen .Actions_Holder .Add_Edit_Resources {
  border: 2px solid #f57b20;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 20px;
  margin-left: 10px;
  cursor: pointer;

}


.Manage_Crew_Main_Screen .Actions_Holder .Delete_Resources:hover,
.Manage_Crew_Main_Screen .Actions_Holder .Add_Edit_Resources:hover {
  transition: 0.3s;
  background-color: #073f2d;
  color: #fff;
  cursor: pointer;
}

.Manage_Crew_Main_Screen .Actions_Holder .Add_Crew_Button:hover,
.Manage_Crew_Main_Screen .Actions_Holder .Add_Edit_Resources:hover {
  transition: 0.3s;
  background-color: #f57b20;
  color: #fff;
  cursor: pointer;
}
.Manage_Crew_Main_Screen .Actions_Holder .Add_Edit_Resources:disabled,
.Manage_Crew_Main_Screen .Actions_Holder .Delete_Resources:disabled {
  background-color: #f4f4f4;
  border: 2px solid  #5d5d5d;
  cursor: not-allowed;
  color: #5d5d5d;
}