.Sheet_Row .Row_Item {
  width: 50px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  display: inherit;
  justify-content: center;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.Sheet_Row .Row_Item:nth-child(2n) {
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
}

.Sheet_Row .Row_Item.Small {
  width: 50px;
}

.Sheet_Row .Row_Item.Medium {
  width: 80px;
}

.Sheet_Row .Row_Item.Large {
  width: 140px;
}

.Row_Item.Medium.grinder {
  width: 100px;
}

.Row_Item.NoWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: start;
}