.Equipment_Crew_Main {
    width: auto;
    height: auto;
    border: 1px solid #e4e4e4;
    background-color: #f3f3f3;
    margin: 0 auto;
}

.Equipment_Resources_Table {
    background-color: #989898;
    border: 1px solid #e4e4e4;
    border-left: none;
    border-right: none;
    max-height: 500px;
    overflow: auto;
}

.Equipment_Resources_Table .Equipment_Table_Header {
    background-color: #fff;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.New_Crew_Title {
    font-size: 14px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 5px;
}


.New_Crew_Title .Wrap {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
}

.New_Crew_Title .Wrap .Title {
    font-size: 14px;
    color: #5d5d5d;
    cursor:auto;
    padding: 0.5rem;
}

.New_Crew_Title .Wrap img {
    height: auto;
    width: auto;
    max-width: 30px;
}


.New_Crew_Title div:last-child{
    cursor: pointer;
}

.Equipment_Resources_Table .Equipment_Table_Header>div:nth-child(1) {
    width: 65.5%;
    padding: 5px 5px;
    border-right: 1px solid #e4e4e4;
}

.Equipment_Resources_Table .Equipment_Table_Header>div:nth-child(2) {
    width: 20%;
    padding: 5px 5px;
    border-right: 1px solid #e4e4e4;
}

.Equipment_Resources_Table .Equipment_Table_Header>div:nth-child(3) {
    width: 15%;
    padding: 5px 5px;
}

.Equipment_Resources_Table .Resource_Name {
    width: 65.5%;
    padding: 3px 5px;
    border-right: 1px solid #e4e4e4;
    padding-left: 1rem;
}

.Equipment_Resources_Table .Resource_Qu {
    width: 20%;
    padding-left: 5px;
    padding: 0px 0px;
    border-right: 1px solid #e4e4e4;
}

.Equipment_Resources_Table .Resource_Qu input {
    width: 100%;
    line-height: 22px;
    border: none;
    text-align: center;
}

.Equipment_Resources_Table .Resource_Line:nth-child(2n) .Resource_Qu input {
    background-color: #fff;
}

.Equipment_Resources_Table .Resource_Line:nth-child(2n+1) .Resource_Qu input {
    background-color: #fff;
}

.Equipment_Resources_Table .Resource_Line .Resource_Qu.Selected input {
    background-color: #20b1ab;
}

.Equipment_Resources_Table .Resource_Check {
    width: 15%;
    padding-left: 5px;
    padding: 3px 5px;
    text-align: center;
}

.Equipment_Resources_Table .Resource_Check input {
    width: 15px;
    height: 15px;
}

.Resources_Actions_Holder {
    background-color: #f3f3f3;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
}

.Resources_Actions_Holder .Button {
    border: 2px solid #073f2d;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.Resources_Actions_Holder .Button:hover {
    transition: 0.3s;
    background-color: #073f2d;
    color: #fff;
}

.Resources_Actions_Holder .Button_Save {
    border: 2px solid #f57b20;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.Resources_Actions_Holder .Button_Save:hover {
    transition: 0.3s;
    background-color: #f57b20;
    color: #fff;
}

.Equipment_Resources_Table .Resource_Line {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e4e4e4;
    color: #747474;
    font-family: Verdana, sans-serif;
    align-items: center;
    padding: 0.3rem 0;
    font-size: 14px;
}

.Equipment_Resources_Table .Resource_Line:nth-child(2n-1) {
    background-color: #fff;
}

.Equipment_Resources_Table .Resource_Line:nth-child(2n) {
    background-color: #fff;
}