@keyframes bounceRight {

  0% {
    -webkit-transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0.4em);
  }

  /* 100% {
    -webkit-transform: translateX(0.4em);
  } */
}

@keyframes bounceLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-0.4em);
  }

  /* 100% {
    -webkit-transform: translateX(-0.4em);
  } */
}

.EquipmentDetailsWindow {
  position: relative;
  width: calc(100% - 50px);
  background-color: #f4f4f4;
  height: calc(100% - 50px);
}

.EquipmentDetailsWindow .Modal_Bar {
  width: 100%;
  background-color: #f3f3f3;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.EquipmentDetailsWindow .Modal_Bar .Title {
  font-size: 14px;
  color: #5d5d5d;
  padding-left: 5px;
}

.EquipmentDetailsWindow .Modal_Bar .Date_Wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.EquipmentDetailsWindow .Modal_Bar .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 35px;
}

.EquipmentDetailsWindow .Modal_Bar .Close {
  width: auto;
  display: block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.EquipmentDetailsWindow .Table {
  width: 100%;
  height: calc(100% - 75px);
  overflow: hidden;
  scroll-behavior: smooth;
}

.EquipmentDetailsWindow .Table .Equipment_Table {
  border-top-width: 0;
  border-left-width: 10px;
  width: 315px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  padding-top: 31px;
  overflow-x: scroll;
  overflow-y: hidden;
  z-index: 3;
  border-right: 1px solid #e4e4e4;
}


.EquipmentDetailsWindow .Table .Scroll_Table {
  display: inline-flex;
  flex-flow: row nowrap;
  /* border: 3px solid #fff; */
  border-top-width: 0;
  width: calc(100% - 315px);
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}

.Equipment_Table .Equip_Name {
  height: 30px;
  text-align: left;
  font-size: 14px;
  padding-left: 1rem;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  border-top: 1px outset rgba(0, 0, 0, 0.2);
}

.Equipment_Table .Equip_Name:nth-child(2n) {
  background-color: #e7e7e7;
}

.EquipmentDetailsWindow .Scroll_Table .Table_Column {
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: #f4f4f4;
  border: 1px outset rgba(0, 0, 0, 0.2);
}



.EquipmentDetailsWindow .Scroll_Table .Table_Column .Date {
  width: 100%;
  text-align: center;
  height: 30px;
  padding: 0 5px;
  opacity: 0;
}


.EquipmentDetailsWindow .Dates .Date {
  width: 100%;
  text-align: center;
  height: 30px;
  padding: 0 5px;
  border-left: 1px outset rgba(0, 0, 0, 0.2);
  border-right: 1px outset rgba(0, 0, 0, 0.2);
}

.EquipmentDetailsWindow .Job_Number {
  height: 30px;
  text-align: center;
  font-size: 14px;
  color: #000;
  position: relative;
  width: 100%;
  border-top:  1px outset rgba(0, 0, 0, 0.2);
}

.EquipmentDetailsWindow .Job_Number:nth-child(2n) {
  background-color: #e7e7e7;
}

.EquipmentDetailsWindow .Job_Number_Bold {
  color: red;
  font-weight: bold;
  height: 30px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  position: relative;
  border-top:  1px outset rgba(0, 0, 0, 0.2);
}

.EquipmentDetailsWindow .Job_Number_Bold:nth-child(2n) {
  background-color: #e7e7e7;
}


.EquipmentDetailsWindow .Scroll_Horizontally_Buttons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 315px);
  margin-left: 315px;
  padding: 0.5rem;
  z-index: 1;
  top: 50%;
}

.EquipmentDetailsWindow .Scroll_Horizontally_Buttons button {
  border: 1px solid #e7e7e7;
  border-radius: 50px;
  background-color: transparent;
  opacity: 0.4;
  transition: 0.3s ease-in-out;
}

.EquipmentDetailsWindow .Scroll_Horizontally_Buttons button:hover {
  opacity: 0.9;
  background-color: #e7e7e7;
  transition: 0.3s ease-in-out;
}

.EquipmentDetailsWindow .Scroll_Horizontally_Buttons .fa-chevron-right {
  color: #073f2c;
  animation: bounceRight 1.5s 10 alternate ease-in-out;
  font-size: 1rem;
}

.EquipmentDetailsWindow .Scroll_Horizontally_Buttons .fa-chevron-left {
  color: #073f2c;
  animation: bounceLeft 1.5s 10 alternate ease-in-out;
  font-size: 1rem;
}

.Dates {
  width: calc(100% - 315px);
  position: absolute;
  top: 47px;
  display: flex;
  margin-left: 315px;
  z-index: 2;
  background-color: #f4f4f4;
  overflow-y: scroll;
  overflow-x: hidden;
}