.NoWorkForForeman {
  width: 50%;
  background-color: #f3f3f3;
  border: 1px solid #e4e4e4;
  margin: 0 auto;
  overflow-y: auto;
  min-height: 350px;
  margin: 0 auto;
  overflow: hidden;
}

.NoWorkForForeman_Header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.NoWorkForForeman_Header .Date_Wrap {
  display: flex;
  align-items: baseline;
  width: 100%;
  padding: 1rem;
}

.NoWorkForForeman_Header .Date_Wrap .Title {
  font-size: 14px;
  color: #5d5d5d;
  margin-left: 10px;
}

.NoWorkForForeman_Header .Date_Wrap img {
  height: 100%;
  width: 100%;
  max-width: 110px;
}

.Close {
  max-width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.NoWorkForForeman_Content .Foremans_List {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  max-height: 300px;
  height: 200px;
}

.NoWorkForForeman .NoWorkForForeman_Content .Foremans_List .Checkbox_Item {
  display: flex;
  width: 100%;
  padding: 0.3rem;
}

.NoWorkForForeman .NoWorkForForeman_Content .Buttons_Wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}

.NoWorkForForeman .NoWorkForForeman_Content .Save {
  background-color: #f57b20;
  border: 2px solid #f57b20;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}

.NoWorkForForeman .NoWorkForForeman_Content .Save:disabled {
  background-color: #d8d8d8;
  border: 2px solid #d8d8d8;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: not-allowed;
}

.NoWorkForForeman .NoWorkForForeman_Content .Cancel {
  background-color: #003F2D;
  border: 2px solid #003F2D;
  color: #fff;
  font-size: 14px;
  padding: 0.4rem 1.2rem;
  margin: 0 1.5rem;
  font-family: Verdana, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}